import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay } from '../utils';
import { Layout } from '../components';

// TODO: This should really be elsewhere imo
export const StyledMainContainer = styled.main`
  ${({
    theme,
  }: any) => theme.mixins.flexCenter};
  flex-direction: column;
`;

const StyledTitle = styled.h1`
  color: var(--yellow);
  font-family: var(--font-mono);
  font-size: clamp(100px, 25vw, 200px);
  line-height: 1;
`;

const StyledSubtitle = styled.h2`
  font-size: clamp(30px, 5vw, 50px);
  font-weight: 400;
`;

const StyledHomeButton = styled(Link)`
  ${({
    theme,
  }: any) => theme.mixins.bigButton};
  margin-top: 40px;
`;

type Props = {
    location: any;
};

const NotFoundPage = ({ location }: Props) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  return (
      <Layout location={location}>
        <Helmet title="Page Not Found" />

        <TransitionGroup component={null}>
        {isMounted && (
                  <CSSTransition timeout={500} classNames="fadeup">
                    <StyledMainContainer className="fillHeight">
                        <StyledTitle>404</StyledTitle>
                        <StyledSubtitle>Page Not Found</StyledSubtitle>
                        <StyledHomeButton to="/">Go Home</StyledHomeButton>
            </StyledMainContainer>
          </CSSTransition>
        )}
      </TransitionGroup>
    </Layout>
  );
};

export default NotFoundPage;
